import { useContext, useEffect, useState } from "react";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index"
import StepOne from "./Components/StepOne";
import StepTwo from "./Components/StepTwo";
import StepThree from "./Components/StepThree";
import StepFour from "./Components/StepFour";
//import { BASE_URL, REGISTER } from "../../Utils/Endpoint";
import StepPin from "./Components/StepPin";
import AuthContext from "../../store/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useGaTracker from "./../../Utils/useGATracker";


const VerifySeamlessOTP = (props) => {

    useGaTracker();

    const location = useLocation()

    console.log(location.state)

    const {
        state: {
            overLimit,
            userExist
        }
    } = location

    const [stepOneInfo,setStepOneInfo] = useState({email : '', phoneCode : '', phoneNumber : ''});
    const [stepTwoInfo,setStepTwoInfo] = useState({firstName : '', lastName : ''});
    const [stepThreeInfo,setStepThreeInfo] = useState({password : ''});
    const [stepPinInfo, setStepPinInfo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const Levels = ["StepOne", "StepTwo", "StepThree", "StepPin", "StepFour"];
    const [level,setLevels] = useState(!overLimit || userExist ? Levels[4] : Levels[0]);
    const AuthCtx = useContext(AuthContext);
    const navigate = useNavigate();

    let setLevelHandler = async (index) => {
        //if(index === 4){
            /*
           var response = await fetch(BASE_URL + REGISTER,
            {
                method : "POST",
                body : JSON.stringify({
                    "firstName": stepTwoInfo.firstName,
                    "lastName": stepTwoInfo.lastName,
                    "phoneNumber": `${stepOneInfo.phoneCode}${stepOneInfo.phoneNumber.charAt(0) === '0' ?
                        stepOneInfo.phoneNumber.substring(1) : 
                        stepOneInfo.phoneNumber} ` ,
                    "userType": "Customer",
                    "email": stepOneInfo.email,
                    "password": stepThreeInfo.password,
                    "pin" : stepPinInfo
                }),
                headers : { 
                    'content-type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            if(response.ok){
                setErrorMessage('');
                setLevels(Levels[index]);
                return null;
            }
            
            let json = await response.json();
            console.log(json);
            
            setErrorMessage(json.statusText);
            */
           // return null;
       // }
       // else{
            setLevels(Levels[index]);
            setErrorMessage('');
       // }
    }
    useEffect(() => {
        
        if(AuthCtx.isLoggedIn)
            navigate(AuthCtx.role === "Customer" ? "/Dashboard" : "/Admin");

    },[AuthCtx,navigate])

    return(
    <OnBoardingCtnr>
        {level === Levels[0] && 
        <StepOne 
            stepOneInfo={stepOneInfo} 
            setStepOneInfo={setStepOneInfo}
            setLevel={setLevelHandler}/>}
        {level === Levels[1] && 
        <StepTwo
            stepTwoInfo={stepTwoInfo} 
            setStepTwoInfo={setStepTwoInfo}
            setLevel={setLevelHandler}
        />}
        {level === Levels[2] && 
        <StepThree
            stepThreeInfo={stepThreeInfo} 
            setStepThreeInfo={setStepThreeInfo}
            setLevel={setLevelHandler}
        />}
        {level === Levels[3] && 
        <StepPin
            pin={stepPinInfo}
            setPin={setStepPinInfo} 
            setLevel={setLevelHandler}
            errorMessage={errorMessage}
        />}
        {level === Levels[4] && 
        <StepFour userDetails={{
            "firstName": stepTwoInfo.firstName,
            "lastName": stepTwoInfo.lastName,
            "phoneNumber": `${stepOneInfo.phoneCode}${stepOneInfo.phoneNumber.charAt(0) === '0' ?
                stepOneInfo.phoneNumber.substring(1) : 
                stepOneInfo.phoneNumber} ` ,
            "userType": "Customer",
            "email": stepOneInfo.email,
            "password": stepThreeInfo.password,
            "pin" : stepPinInfo,
            overLimit
        }}/>}    
    </OnBoardingCtnr>);

}

export default VerifySeamlessOTP;