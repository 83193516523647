// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css_Login__6BAdl{text-align: left;}
.css_Login__6BAdl > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px; }
.css_Login__6BAdl > a{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;margin-bottom: 20px;text-decoration: none;display: inline-block;}
.css_Login__6BAdl > p{margin: 0px;margin-top: 32px;color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}
.css_Login__6BAdl > p > a, .css_Login__6BAdl > p > button{color: var(--accent, #F60);text-decoration: none;margin-left: 4px;}
.css_Login__6BAdl > p > button{background-color: rgba(0, 0, 0, 0);border: none; font-weight: 500;}
.css_ErrorMessage__Doj1e{color: red; font-size: 14px;margin-top: 12px;display: block;}
p.css_marginBottom__VgKq3{margin-bottom: 16px;}`, "",{"version":3,"sources":["webpack://./src/Pages/Login/css/index.module.css"],"names":[],"mappings":"AAAA,kBAAO,gBAAgB,CAAC;AACxB,uBAAY,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,WAAW,CAAC,mBAAmB,EAAE;AACnJ,sBAAW,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,mBAAmB,CAAC,qBAAqB,CAAC,qBAAqB,CAAC;AACjL,sBAAW,WAAW,CAAC,gBAAgB,CAAC,+BAA+B,CAAC,eAAe,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,iBAAiB,EAAE,SAAS,CAAC;AACzJ,0DAAoC,0BAA0B,CAAC,qBAAqB,CAAC,gBAAgB,CAAC;AACtG,+BAAoB,kCAAkC,CAAC,YAAY,EAAE,gBAAgB,CAAC;AACtF,yBAAc,UAAU,EAAE,eAAe,CAAC,gBAAgB,CAAC,cAAc,CAAC;AAC1E,0BAAe,mBAAmB,CAAC","sourcesContent":[".Login{text-align: left;}\n.Login > h3{color: var(--gray-900, #101828);font-size: 24px;font-style: normal;font-weight: 500;line-height: 32px;margin: 0px;margin-bottom: 32px; }\n.Login > a{color: var(--gray-500, #667085);font-size: 16px;font-style: normal;font-weight: 500;line-height: 24px;margin-bottom: 20px;text-decoration: none;display: inline-block;}\n.Login > p{margin: 0px;margin-top: 32px;color: var(--gray-900, #101828);font-size: 16px;font-style: normal;font-weight: 450;line-height: 24px; /* 150% */}\n.Login > p > a, .Login > p > button{color: var(--accent, #F60);text-decoration: none;margin-left: 4px;}\n.Login > p > button{background-color: rgba(0, 0, 0, 0);border: none; font-weight: 500;}\n.ErrorMessage{color: red; font-size: 14px;margin-top: 12px;display: block;}\np.marginBottom{margin-bottom: 16px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Login": `css_Login__6BAdl`,
	"ErrorMessage": `css_ErrorMessage__Doj1e`,
	"marginBottom": `css_marginBottom__VgKq3`
};
export default ___CSS_LOADER_EXPORT___;
