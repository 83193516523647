import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./../css/index.module.css";
import BTC from "./../../../../img/BTC.png";
import PM from "./../../../../img/PM.png"
import USDT from "./../../../../img/USDT.png";
import ArrowDown from "./../../../../img/ArrowDown.png";
import Button from "../../../../Components/Button/Index";
import Buy from "../../Modals/Buy/Index";
import Sell from "../../Modals/Sell/Index";
import Swap from "../../Modals/Swap/Index";
import { GetRates, GetLimitStatus, GetLatestFees } from "../../../../Utils/GetData";
import BankTransfer from "../../Modals/BankTransfer/Index";
import Pending from "../../Modals/Pending/Index";
import Completed from "../../Modals/Completed/Index";
import { BASE_URL, BUY, GET_CRYPTO_WALLET, GET_WALLET_AMOUNT, SELL, SWAP } from "../../../../Utils/Endpoint";
import AuthContext from "../../../../store/AuthContext";
import Pin from "../../Modals/Pin/Index";
import IsLimited from "../../Modals/IsLimited/Index";

const TradeBlock = () => {
    const TradeType = useMemo(() => ["Buy", "Sell", "Swap"], []);
    const Cryptos = useMemo(() => [
        {
            shortCode : "BTC",
            name : "Bitcoin",
            img : BTC
        },
        {
            shortCode : "USDT",
            name : "USDT",
            img : USDT
        },
        {
            shortCode : "USDC",
            name : "USDC",
            img : USDT
        }
    ], [])
    const Currency = ["NGN", "USD"];
    const [cryptoList, setCryptoList] = useState(Cryptos);
    const [activeTradeType, setType] = useState(TradeType[0]);
    const [showAssetOpt, setShowAssetOpt] = useState(false);
    const [showAssetOpt2, setShowAssetOpt2] = useState(false);
    const [showNetworkOpt, setShowNetworkOpt] = useState(false);
    const [showNetworkOpt2, setShowNetworkOpt2] = useState(false);
    const [showBankTransfer, setShowBankTransfer] = useState(false);
    const [showPending, setShowPending] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);
    const [asset, setAsset] = useState(null);
    const [asset2, setAsset2] = useState(null);
    const [network, setNetwork] = useState(null);
    const [network2, setNetwork2] = useState(null);
    const [buyMethod, setBuyMethod] = useState(null);
    const [amount, setAmount] = useState('');
    const [balance, setBalance] = useState(0);
    const [sellRec, setSellRec] = useState(null);
    const [rates, setRates] = useState(null);
    const [fees, setFees] = useState(null);
    const [buyFee, setBuyFee] = useState(null);
    const [buyWalletAddress, setBuyWalletAddress] = useState('');
    const [swapWalletAddress, setSwapWalletAddress] = useState('');
    const [activeRate1, setActiveRate1] = useState(null);
    const [activeRate2, setActiveRate2] = useState(null);
    const [pin, setPin] = useState('');
    const [showPinModal, setShowPinModal] = useState(false);
    const [buyNairaRate, setBuyNairaRate] = useState(null);
    const [sellNairaRate, setSellNairaRate] = useState(null);
    const [showBuyModal, setBuyModal] = useState(false);
    const [showSellModal, setSellModal] = useState(false);
    const [showSwapModal, setSwapModal] = useState(false);
    const [buyError, setBuyError] = useState('');
    const [limitedModal,setLimitedModal] = useState(false);
    const [buyCurrency, setBuyCurrency] = useState(Currency[1]);
    const [showBuyCur, setBuyCur] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [cryptoWallets, setCryptoWallets] = useState([]);
    const [sellAddress, setSellAddress] = useState(null);
    const [swapAddress, setSwapAddress] = useState(null);
    const [limit, setLimit] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const AuthCtx = useContext(AuthContext);

    
    let checkLimit = async (amount, currency) => {
        let response = await GetLimitStatus(AuthCtx.token, amount, currency);
        return response;
    }
    

    let setModalCheck = async (setTransModal, amount, currency) => {

        
        let response = await checkLimit(amount, currency);

        if(!response?.isLimited){
            setTransModal(true);
            return null;
        }

        setLimitedModal(true);
        setLimit(response?.limit);    
    }

    let setAction = async () => {

        if(activeTradeType === TradeType[0])
            await setModalCheck(setBuyModal, parseFloat(amount), buyCurrency)
        if(activeTradeType === TradeType[1])
            await setModalCheck(setSellModal, parseFloat(amount), "USD")
        if(activeTradeType === TradeType[2])
            await setModalCheck(setSwapModal, parseFloat(amount), "USD")
    }

    let BuyPost = async () => {

        setIsLoading(true);
        if(amount > balance && buyMethod.name !== "Bank Transfer"){
            setBuyError("You do not have enough in your balance to complete this transaction.");
            return null;
        }
        var convertedAmount = buyCurrency === Currency[0] ? parseFloat(amount) : parseFloat(amount * buyNairaRate.rate)
        var obj = {
            "assetId": asset?.shortCode,
            "walletAddress": buyWalletAddress,
            "amount": convertedAmount,
            "paymentMethod": buyMethod.code,
            "bankAccountId": null,
            "destinationAddress": buyWalletAddress,
            "destinationNetworkType": network,
            "destinationRateId": activeRate1.id,
            "nairaRateId": buyNairaRate.id,
            "feeId": buyFee.id
          };
        console.log(obj);
    var response = await fetch(BASE_URL + BUY,
        {
            method : "POST",
            body : JSON.stringify(obj),
            headers : {
                'content-type': 'application/json',
                "Authorization" : "Bearer " + AuthCtx.token,
                'Accept': 'application/json'
            }
    })

        if(response.ok){
            if(buyMethod.name === "Bank Transfer")
                setShowBankTransfer(false);
            else
                setBuyModal(false);
            
            setBuyError('');
            setShowPending(true);
        }
        setIsLoading(false);
    }

    const ConfirmBuyAction = () => {
        if(buyMethod.name === "Bank Transfer"){
            setBuyModal(false)
            setShowBankTransfer(true)
        }
        else
            BuyPost();
    }

    const SetSellConfirm = () => {
        setSellModal(false);
        setShowPinModal(true);
    }

    const SetSwapConfirm = () => {
        setSwapModal(false);
        setShowPinModal(true);
    }

    const PinAction = async () => {
        var obj = {};
        var ENDPOINT = "";
        setIsLoading(true);
        if(activeTradeType === TradeType[1]){
            obj = {
                "assetId": asset?.shortCode,
                "amount": parseFloat(amount * sellNairaRate.rate),
                "pin": pin,
                "paymentMethod": sellRec.type,
               // "sourceAddress": "string",
                "sourceNetworkType": network !== null ? network : null,
                "sourceRateId": activeRate1.id,
                "nairaRateId": sellNairaRate.id,
                "bankAccountId": sellRec.type === "wallet" ? null : sellRec.id
              };
            ENDPOINT = SELL;  
        }else if(activeTradeType === TradeType[2]){
            obj = {
                "sourceAssetId": asset?.shortCode,
                "destinationAssetId": asset2?.shortCode,
                "amount": parseFloat(amount * buyNairaRate.rate),
                "pin": pin,
                //"sourceAddress": "string",
                "sourceRateId": activeRate1.id,
                "sourceNetworkType": network !== null ? network : null,
                "destinationAddress": swapWalletAddress,
                "destinationNetworkType": network2 !== null ? network2 : null,
                "destinationRateId": activeRate2.id,
                "buyNairaRateId": buyNairaRate.id,
                "sellNairaRateId": sellNairaRate.id,
                "feeId": buyFee.id
              };
            ENDPOINT = SWAP
        }
        console.log(obj);
        var response = await fetch(BASE_URL + ENDPOINT,
            {
                method : "POST",
                body : JSON.stringify(obj),
                headers : {
                    'content-type': 'application/json',
                    "Authorization" : "Bearer " + AuthCtx.token,
                    'Accept': 'application/json'
                }
        })

        if(response.ok){
            setShowPinModal(false);
            setShowPending(true);
            setIsLoading(false)
            return;
        }

        let json = await response.json();

        setErrorMessage(json.statusText);
        setIsLoading(false);

    }


    const Networks = [
        "ERC-20",
        "TRC-20"
    ]

    useEffect(() => {

        

        if(activeTradeType === TradeType[2] && asset === asset2){
            setAsset2(null)
        }
        else if(asset !== null)
            setCryptoList(prevState => Cryptos.filter(prev => asset?.shortCode !== prev.shortCode))
        else
            setCryptoList(Cryptos);

    },[Cryptos, asset, asset2, activeTradeType, TradeType])

    useEffect(() => {

        if(activeTradeType !== TradeType[2]){

            setAsset2(null);
            setNetwork2(null);

        }else if(asset?.shortCode !== Cryptos[1].shortCode || asset?.shortCode !== Cryptos[2].shortCode){
            setNetwork(null);
        }else if(asset2?.shortCode !== Cryptos[1].shortCode || asset?.shortCode !== Cryptos[2].shortCode){
            setNetwork2(null);
        }

    },[activeTradeType, TradeType, asset, asset2, Cryptos])

    useEffect(() => {
        let GetLatestRatesAndFees = async () => {
            let resRates = await GetRates();
            let resFees = await GetLatestFees();
            setRates(resRates);
            setFees(resFees);
        }

        GetLatestRatesAndFees();
    },[]);


    useEffect(() => {


        if(asset !== null && rates !== null)
            setActiveRate1(rates.filter(x => x.currencyShort === asset.shortCode)[0]);

        if(asset2 !== null && rates !== null)
            setActiveRate2(rates.filter(x => x.currencyShort === asset2.shortCode)[0]);

        if(rates !== null && activeTradeType === TradeType[0] && asset !== null)
            setBuyNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[0]}`)[0])

        if(rates !== null && activeTradeType === TradeType[1] && asset !== null)
            setSellNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[1]}`)[0])

        if(rates !== null && activeTradeType === TradeType[2] && asset !== null && asset2 !== null){
            setBuyNairaRate(rates.filter(x => x.currencyShort === `${asset2.shortCode}-${TradeType[0]}`)[0])
            setSellNairaRate(rates.filter(x => x.currencyShort === `${asset.shortCode}-${TradeType[1]}`)[0])
        }

    },[asset,asset2,rates,TradeType,activeTradeType,amount])

    useEffect(() => {
        if(cryptoWallets.length !== 0){

            if(activeTradeType === TradeType[1] && asset !== null && asset?.shortCode !== Cryptos[2].shortCode)
                setSellAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}`)[0].address)
            else if (activeTradeType === TradeType[1] && asset !== null && asset?.shortCode === Cryptos[2].shortCode && network !== null)
                setSellAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}-${network}`)[0].address)

            if(activeTradeType === TradeType[2] && asset !== null && asset?.shortCode !== Cryptos[2].shortCode)
                setSwapAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}`)[0].address)
            else if (activeTradeType === TradeType[2] && asset !== null && asset?.shortCode === Cryptos[2].shortCode && network !== null)
                setSwapAddress(cryptoWallets.filter(x => x.currencyShort === `${asset.shortCode}-${network}`)[0].address)

        }
    },[cryptoWallets, asset, rates, activeTradeType, TradeType, Cryptos, network])

    useEffect(() => {

            setBuyFee(prev => {
                if(activeTradeType === TradeType[0]){
                    if(asset === null){
                        return null
                    }
                    else if(asset.shortCode === Cryptos[1].shortCode && network !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset.shortCode}-${network}`)[0] 
                    }
                    else if(asset.shortCode === Cryptos[2].shortCode && network !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset.shortCode}-${network}`)[0] 
                    }
                    else if(asset.shortCode !== Cryptos[1].shortCode){
                        return fees.filter(fee => fee.currencyShort ===`${asset.shortCode}`)[0]
                    }
                    else
                    {
                        return null
                    }
                }else if(activeTradeType === TradeType[2]){
                    if(asset2 === null){
                        return null
                    }
                    else if(asset2.shortCode === Cryptos[1].shortCode && network2 !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset2.shortCode}-${network2}`)[0]
                    }
                    else if(asset2.shortCode === Cryptos[2].shortCode && network2 !== null){
                        return fees.filter(fee => fee.currencyShort ===`${asset2.shortCode}-${network2}`)[0]
                    }
                    else if(asset2.shortCode !== Cryptos[1].shortCode){
                        return fees.filter(fee => fee.currencyShort ===`${asset2.shortCode}`)[0]
                    }
                    else
                    {
                        return null
                    }
                }else{
                    return null
                }
            })

    },[asset, asset2, activeTradeType, TradeType, fees, Cryptos, network, network2])

    useEffect(() => {

        let getAmount = async () => {
            
            var response = await fetch(BASE_URL + GET_WALLET_AMOUNT,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })

                if(response.ok){
                    let json = await response.json();
                    setBalance(json.data.amount);
                }
        }

        let getAddresses = async () => {
            
            var response = await fetch(BASE_URL + GET_CRYPTO_WALLET,
                {
                    method : "GET",
                    headers : {
                        'content-type': 'application/json',
                        "Authorization" : "Bearer " + AuthCtx.token,
                        'Accept': 'application/json'
                    }
                })

                if(response.ok){
                    let json = await response.json();
                    setCryptoWallets(json.data);
                }
        }
    
        getAmount();
        getAddresses();
    },[AuthCtx])


    return(
        <>
            {showBuyModal && <Buy 
                setBuyModal={setBuyModal} 
                buyWalletAddress={buyWalletAddress} 
                setBuyWalletAddress={setBuyWalletAddress} 
                buyMethod={buyMethod} 
                setBuyMethod={setBuyMethod} 
                amount={buyCurrency === Currency[0] ? parseFloat(amount) : parseFloat(amount * buyNairaRate.rate)} 
                confirmBuyAction={ConfirmBuyAction} 
                asset={asset}
                balance={balance}
                buyError={buyError}
                buyNairaRate={buyNairaRate}
                buyFee={buyFee}
                isLoading={isLoading}/>}
            {showSellModal && <Sell 
                setSellConfirm={SetSellConfirm} 
                setSellModal={setSellModal} 
                amount={amount} 
                nairaRate={sellNairaRate} 
                asset={asset} 
                sellRec={sellRec} 
                setSellRec={setSellRec}
                rate={activeRate1}
                address={sellAddress}/>}
            {showSwapModal && <Swap 
                setSwapModal={setSwapModal}
                asset1={asset}
                asset2={asset2}
                rate={activeRate1}
                amount={amount}
                walletAddress={swapWalletAddress}
                setWalletAddress={setSwapWalletAddress}
                setConfirmTrans={SetSwapConfirm}
                buyNairaRate={buyNairaRate}
                sellNairaRate={sellNairaRate}
                swapFee={buyFee}
                address={swapAddress}/>}
            {showPinModal && <Pin 
                pin={pin} 
                setPin={setPin} 
                setPinModal={setShowPinModal} 
                setPinConfirm={PinAction}
                errorMessage={errorMessage}
                isLoading={isLoading}/>}
            {showBankTransfer && <BankTransfer
                depAmount={amount}
                rate={buyNairaRate.rate}
                setBankTransfer={setShowBankTransfer}
                depositAction={BuyPost}
                isLoading={isLoading}
             />}
            {showPending && <Pending
                setIsPending={setShowPending}
                setIsCompleted={setShowCompleted}
                type={activeTradeType}
             />}
            {showCompleted && <Completed
                setIsCompleted={setShowCompleted}
             />}
            {limitedModal && <IsLimited showModal={setLimitedModal} limit={limit}/>}
            <div className={styles.TradeBlock}>
                <div>
                    <button 
                        className={activeTradeType === TradeType[0] ? styles.IsActive : "" }
                        onClick={() => {setType(TradeType[0])}}
                    >Buy</button>
                    <button 
                        className={activeTradeType === TradeType[1] ? styles.IsActive : "" }
                        onClick={() => setType(TradeType[1])}
                    >Sell</button>
                    <button 
                        className={activeTradeType === TradeType[2] ? styles.IsActive : "" }
                        onClick={() => setType(TradeType[2])}
                    >Swap Your Asset</button>
                </div>
                <div>
                    <div className={styles.Asset}>
                        <label>Asset</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowAssetOpt(prevState => !prevState)}>
                            <div >
                                <div>
                                    {asset !== null ? 
                                    <>
                                        <img src={asset.img} alt="Active Coin" className={styles.coinSize}/>
                                        <label>{asset.name}</label>
                                    </> :
                                    <label>Select Asset</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showAssetOpt &&
                            <div>
                                {cryptoList.map((crypto, index) => (
                                    <div key={index} onClick={() => {setAsset(crypto);}}>
                                        <img src={crypto.img} alt="Active Coin" className={styles.coinSize}/>
                                        <label>{crypto.name}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>
                    {(asset?.name === "USDT" || asset?.name === "USDT") &&
                    <div className={`${styles.Asset} ${styles.TopMargin}`}>
                        <label>Network Type</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowNetworkOpt(prevState => !prevState)}>
                            <div >
                                <div>
                                    {network !== null ? 
                                    <>
                                        <label>{network}</label>
                                    </> :
                                    <label>Select Network</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showNetworkOpt &&
                            <div>
                                {Networks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>}
                    {activeTradeType === TradeType[2] &&
                    <div className={`${styles.Asset} ${activeTradeType === TradeType[2] ? styles.SwapMargin : ""}`}>
                        <label>Swap for</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowAssetOpt2(prevState => !prevState)}>
                                <div >
                                    <div>
                                        {asset2 !== null ? 
                                        <>
                                            <img src={asset2.img} alt="Active Coin" className={styles.coinSize}/>
                                            <label>{asset2.name}</label>
                                        </> :
                                        <label>Select Asset</label>
                                        }
                                    </div>
                                    <img src={ArrowDown} alt="Arrow Down"/>
                                </div>
                                {showAssetOpt2 &&
                                <div>
                                    {cryptoList.map((crypto, index) => (
                                        <div key={index} onClick={() => {setAsset2(crypto);}}>
                                            <img src={crypto.img} alt="Active Coin" className={styles.coinSize}/>
                                            <label>{crypto.name}</label>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                    </div>}
                    {(asset2?.name === "USDT" || asset2?.name === "USDC") &&
                    <div className={styles.Asset}>
                        <label>Network Type</label>
                        <div className={styles.AssetCtnr} onClick={() => setShowNetworkOpt2(prevState => !prevState)}>
                            <div >
                                <div>
                                    {network2 !== null ? 
                                    <>
                                        <label>{network2}</label>
                                    </> :
                                    <label>Select Network</label>
                                    }
                                </div>
                                <img src={ArrowDown} alt="Arrow Down"/>
                            </div>
                            {showNetworkOpt2 &&
                            <div>
                                {Networks.map((netwk, index) => (
                                    <div key={index} onClick={() => setNetwork2(netwk)}>
                                        <label>{netwk}</label>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    </div>}
                    <div className={`${styles.Amount} ${activeTradeType === TradeType[2] ? styles.SwapMargin : ""}`}>
                        <label>Amount</label>
                        <div> 
                            <label className={styles.Currency} onClick={() => {if(activeTradeType === TradeType[0]) setBuyCur(prev => !prev)}}>
                                {activeTradeType === TradeType[0] ? 
                                <>
                                    <span>{buyCurrency}</span> 
                                    <img src={ArrowDown} alt="Arrow Down" className={styles.currencySpace}/>
                                </>
                                 : 'USD'}
                            {showBuyCur &&
                            <div>
                                {Currency.map((cur, index) => 
                                    (<label key={index} onClick={() => setBuyCurrency(cur)}>{cur}</label>))}
                            </div>}
                            </label>
                            <input type="number" onChange={(e) => setAmount(e.target.value)} value={amount} />
                        </div>
                    </div>
                    {activeTradeType === TradeType[0] &&
                    <div className={styles.AccountLimit}>
                        <p>Your account trading limit is $5,000</p>
                        <button>Verify Now</button>
                    </div>}
                    {activeTradeType === TradeType[0] &&
                    <div className={styles.Summary}>
                        <div>
                            <span>Total est.</span>
                            {/*console.log(buyNairaRate, ' ', activeRate1, ' ', amount)*/}
                            <span>{!buyNairaRate || !activeRate1 || amount === '' ? "---" : `${buyCurrency === Currency[0] ? (amount/(activeRate1.rate * buyNairaRate.rate)).toFixed(8) : (amount/(activeRate1.rate)).toFixed(8)} ${asset.shortCode}`}</span>
                        </div>
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {!buyNairaRate ? " ---" : buyNairaRate.rate} / USD</span>
                        </div>
                        <div>
                            <span>Fee</span>
                            <span>{buyCurrency} {!buyNairaRate || !buyFee ? " ---" : buyCurrency === Currency[0] ? buyFee.feeCharge :  (buyFee.feeCharge/buyNairaRate.rate).toFixed(2)}</span>
                        </div>
                    </div>}
                    {activeTradeType === TradeType[1] &&
                    <div className={styles.Summary}>
                        <div>
                            <span>You will receive est.</span>
                            <span>{!sellNairaRate || amount === '' ? "---" : parseFloat(amount * sellNairaRate.rate).toFixed(2)} NGN</span>
                        </div>
                        {asset !== null &&
                        <div>
                            <span>{asset.shortCode} Amount</span>
                            <span>{!sellNairaRate || amount === '' ? "---" : parseFloat(amount / activeRate1.rate).toFixed(8)} {asset.shortCode}</span>
                        </div>}
                        <div>
                            <span>Exchange rate</span>
                            <span>NGN {!sellNairaRate ? "---" : parseFloat(sellNairaRate.rate).toFixed(2)} / USD</span>
                        </div>
                    </div>}
                    <Button
                        text={"Continue"}
                        isEnabled={true}
                        action={setAction}
                    />
                </div>
            </div>
        </>
    )

}

export default TradeBlock;