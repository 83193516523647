//import logo from './logo.svg';
import './App.css';
import About from './Pages/About/Index';
import Contact from './Pages/Contact/Index';
import FAQs from './Pages/FAQs/Index';
import Home from './Pages/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './Pages/PrivacyPolicy/Index';
import TermsOfUse from './Pages/TermsOfUse/Index';
import AntiMoney from './Pages/AntiMoney/Index';
import OnBoarding from './Pages/OnBoarding/Index';
import Login from './Pages/Login/Index';
import ForgotPassword from './Pages/ForgotPassword/Index';
import ResetPassword from './Pages/ResetPassword/Index';
import AuthCustContainer from './Components/AuthCustContainer/Index';
import CDashboard from './Pages/Customer/Dashboard/Index';
import CExchange from './Pages/Customer/Exchange/Index';
import CAccount from './Pages/Customer/Account/Index';
import CSettings from './Pages/Customer/Settings/Index';
import CustSettingsContainer from './Components/CustSettingsContainer/Index';
import General from './Pages/Customer/Settings/Pages/General/Index';
import GeneralEdit from './Pages/Customer/Settings/Pages/GeneralEdit/Index';
import Notifications from './Pages/Customer/Settings/Pages/Notifications/Index';
import AccountUpgrade from './Pages/Customer/Settings/Pages/AccountUpgrade/Index';
import BankAccounts from './Pages/Customer/Settings/Pages/BankAccounts/Index';
import ReferAndEarn from './Pages/Customer/Settings/Pages/ReferAndEarn/Index';
import Security from './Pages/Customer/Settings/Pages/Security/Index';
import ChangePassword from './Pages/Customer/Settings/Pages/ChangePassword/Index';
import AuthAdminContainer from './Components/AuthAdminContainer/Index';
import ADashboard from './Pages/Admin/Dashboard/Index';
import ATransactions from './Pages/Admin/Transactions/Index';
import Customers from './Pages/Admin/Customers/Index';
import CustomerContainer from './Pages/Admin/Customers/Pages/CustomerContainer/Index';
import CustomerTransaction from './Pages/Admin/Customers/Pages/CustomerTransaction/Index';
import CustomerProfile from './Pages/Admin/Customers/Pages/CustomerProfile/Index';
import CustomerEdit from './Pages/Admin/Customers/Pages/CustomerEdit/Index';
import CustomerBank from './Pages/Admin/Customers/Pages/CustomerBank/Index';
import Users from './Pages/Admin/Users/Index';
import Settings from './Pages/Admin/Settings/Index';
import ANotifications from './Pages/Admin/Settings/Pages/Notifications/Index';
import STransactions from './Pages/Admin/Settings/Pages/Transactions/Index';
import Rates from './Pages/Admin/Settings/Pages/Rates/Index';
import RateSetting from './Pages/Admin/Settings/Pages/RateSetting/Index';
import { ACTIONS, CURRENCIES, USDT_NETWORKS } from './Utils/StaticData';
import Timers from './Pages/Admin/Settings/Pages/Timers/Index';
import TimerSetting from './Pages/Admin/Settings/Pages/TimerSetting/Index';
import { AuthContextProvider } from './store/AuthContext';
import ProtectedPages from './Components/ProtectedPages/Index';
import AddBankAccount from './Pages/Customer/Settings/Pages/AddBankAccount/Index';
import BankAccountDetails from './Pages/Customer/Settings/Pages/BankAccountDetails/Index';
import CryptoWallet from './Pages/Admin/Settings/Pages/CryptoWallet/Index';
import CryptoWalletSetting from './Pages/Admin/Settings/Pages/CryptoWalletSetting/Index';
import CountDown from './Pages/CountDown/Index';
import Fees from './Pages/Admin/Settings/Pages/Fees/Index';
import FeeSetting from './Pages/Admin/Settings/Pages/FeeSetting/Index';
import VerifySeamlessOTP from './Pages/VerifySeamlessOTP/Index';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            <Route element={<CountDown/>} path='/Hidden' />
            <Route element={<Home/>} path='/'/>
            <Route element={<About/>} path='/About'/>
            <Route element={<FAQs/>} path='/FAQs' />
            <Route element={<Contact/>} path='/Contact' />
            <Route element={<PrivacyPolicy/>} path='/PrivacyPolicy' />
            <Route element={<TermsOfUse/>} path='/TermsOfUse' />
            <Route element={<AntiMoney/>} path='/AntiMoneyLaundering' />
            <Route element={<OnBoarding/>} path='/OnBoarding' />
            <Route element={<VerifySeamlessOTP/>} path='/Seamless' />
            <Route element={<Login/>} path='/Login' />
            <Route element={<ForgotPassword/>} path='/ForgotPassword' />
            <Route element={<ResetPassword/>} path='/ResetPassword' />
            <Route 
              element={
                <ProtectedPages>
                  <AuthCustContainer/>
                </ProtectedPages>  
              } path='/Dashboard'>
                <Route index element={<CDashboard/>} />
                <Route element={<CExchange/>} path='Exchange' />
                <Route element={<CAccount/>} path='Account' />
                <Route element={<CustSettingsContainer/>} path='Settings' >
                  <Route index element={<CSettings/>} />
                  <Route element={<CustSettingsContainer/>} path='General'>
                    <Route index element={<General/>} />
                    <Route element={<GeneralEdit/>} path='Edit'/>
                  </Route>
                  <Route element={<Notifications/>} path='Notifications' />
                  <Route element={<AccountUpgrade/>} path='AccountUpgrade' />
                  <Route element={<CustSettingsContainer/>} path='BankAccounts' >
                    <Route element={<BankAccounts/>} index />
                    <Route element={<AddBankAccount/>} path='Add' />
                    <Route element={<BankAccountDetails/>} path='Details'/>
                  </Route>
                  <Route element={<ReferAndEarn/>} path='ReferAndEarn' />
                  <Route element={<CustSettingsContainer/>} path='Security'>
                    <Route index element={<Security/>} />
                    <Route element={<ChangePassword/>} path='ChangePassword'/>
                  </Route>
                </Route>
            </Route>
            <Route 
            element={<ProtectedPages>
              <AuthAdminContainer/>
            </ProtectedPages>  
            } path='/Admin'>
              <Route index element={<ADashboard/>} /> 
              <Route element={<ATransactions/>} path='Transactions'/>
              <Route element={<Customers/>} path='Customers'/>
              <Route element={<Users/>} path='Users' />
              <Route element={<CustSettingsContainer/>} path='Settings' >
                <Route element={<Settings/>} index />
                <Route element={<CustSettingsContainer/>} path='General'>
                  <Route index element={<General isAdmin={true}/>} />
                  <Route element={<GeneralEdit isAdmin={true}/>} path='Edit'/>
                </Route>
                <Route element={<CustSettingsContainer/>} path='Security'>
                  <Route index element={<Security isAdmin={true}/>} />
                  <Route element={<ChangePassword isAdmin={true}/>} path='ChangePassword'/>
                </Route>
                <Route element={<ANotifications/>} path='Notifications'/>
                <Route element={<CustSettingsContainer/>} path='Transactions'>
                  <Route element={<STransactions/>} index />
                  <Route element={<CustSettingsContainer/>} path='Rates'>
                    <Route element={<Rates/>} index />
                   {/*  <Route element={<RateSetting rate={CURRENCIES[0]}/>} path={CURRENCIES[0].ShortCode} />
                    <Route element={<RateSetting rate={CURRENCIES[1]}/>} path={CURRENCIES[1].ShortCode} /> */}
                    <Route element={<RateSetting rate={CURRENCIES[2]}/>} path={CURRENCIES[2].ShortCode} />
                    <Route element={<RateSetting rate={{...CURRENCIES[2], ...ACTIONS[0], isUSDRate : true}}/>} path={`${CURRENCIES[2].ShortCode}-${ACTIONS[0].Short}`} />
                    <Route element={<RateSetting rate={{...CURRENCIES[2], ...ACTIONS[1], isUSDRate : true}}/>} path={`${CURRENCIES[2].ShortCode}-${ACTIONS[1].Short}`} />
                    <Route element={<RateSetting rate={CURRENCIES[3]}/>} path={CURRENCIES[3].ShortCode} />
                    <Route element={<RateSetting rate={{...CURRENCIES[3], ...ACTIONS[0], isUSDRate : true}}/>} path={`${CURRENCIES[3].ShortCode}-${ACTIONS[0].Short}`} />
                    <Route element={<RateSetting rate={{...CURRENCIES[3], ...ACTIONS[1], isUSDRate : true}}/>} path={`${CURRENCIES[3].ShortCode}-${ACTIONS[1].Short}`} />
                    <Route element={<RateSetting rate={CURRENCIES[4]}/>} path={CURRENCIES[4].ShortCode} />
                    <Route element={<RateSetting rate={{...CURRENCIES[4], ...ACTIONS[0], isUSDRate : true}}/>} path={`${CURRENCIES[4].ShortCode}-${ACTIONS[0].Short}`} />
                    <Route element={<RateSetting rate={{...CURRENCIES[4], ...ACTIONS[1], isUSDRate : true}}/>} path={`${CURRENCIES[4].ShortCode}-${ACTIONS[1].Short}`} />
                  </Route>
                  <Route element={<CustSettingsContainer/>} path='Fees'>
                    <Route element={<Fees/>} index />
                   {/*  <Route element={<RateSetting rate={CURRENCIES[0]}/>} path={CURRENCIES[0].ShortCode} />
                    <Route element={<RateSetting rate={CURRENCIES[1]}/>} path={CURRENCIES[1].ShortCode} /> */}
                    <Route element={<FeeSetting fee={{...CURRENCIES[2]}}/>} path={CURRENCIES[2].ShortCode} />
                    <Route element={<FeeSetting fee={{...CURRENCIES[3]}}/>} path={CURRENCIES[3].ShortCode} />
                    <Route element={<FeeSetting fee={{...CURRENCIES[4], network: USDT_NETWORKS[0], isUSDRate : true}}/>} path={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[0]}`} />
                    <Route element={<FeeSetting fee={{...CURRENCIES[4], network: USDT_NETWORKS[1], isUSDRate : true}}/>} path={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[1]}`} />
                  </Route>
                  <Route element={<CustSettingsContainer/>} path='CryptoWallet'>
                    <Route element={<CryptoWallet/>} index />
                   {/*  <Route element={<RateSetting rate={CURRENCIES[0]}/>} path={CURRENCIES[0].ShortCode} />
                    <Route element={<RateSetting rate={CURRENCIES[1]}/>} path={CURRENCIES[1].ShortCode} /> */}
                    <Route element={<CryptoWalletSetting cryptoWallet={CURRENCIES[2].ShortCode}/>} path={CURRENCIES[2].ShortCode} />
                    <Route element={<CryptoWalletSetting cryptoWallet={CURRENCIES[3].ShortCode}/>} path={CURRENCIES[3].ShortCode} />
                    <Route element={<CryptoWalletSetting cryptoWallet={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[0]}`}/>} path={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[0]}`} />
                    <Route element={<CryptoWalletSetting cryptoWallet={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[1]}`}/>} path={`${CURRENCIES[4].ShortCode}-${USDT_NETWORKS[1]}`} />
                  </Route>
                  <Route element={<CustSettingsContainer/>} path='Timers' >
                    <Route element={<Timers/>} index />
                    <Route element={<TimerSetting action={ACTIONS[0]} />} path={ACTIONS[0].Short} />
                    <Route element={<TimerSetting action={ACTIONS[1]} />} path={ACTIONS[1].Short} />
                    <Route element={<TimerSetting action={ACTIONS[2]} />} path={ACTIONS[2].Short} />
                    <Route element={<TimerSetting action={ACTIONS[3]} />} path={ACTIONS[3].Short} />
                    <Route element={<TimerSetting action={ACTIONS[4]} />} path={ACTIONS[4].Short} />
                  </Route>
                </Route>
              </Route>
              <Route element={<CustomerContainer/>} path='Customer/:id'>
                <Route index element={<CustomerTransaction/>}/>
                <Route element={<CustSettingsContainer/>} path='Profile'>
                  <Route index element={<CustomerProfile/>}/>
                  <Route element={<CustomerEdit/>} path='Edit'/>
                </Route>
                <Route element={<CustomerBank/>} path='Bank'/>
              </Route>
            </Route>
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
