const BASE_URLS = [
    "https://localhost:7033/",
    "https://api.coinnest.africa/"
];

export const BASE_URL = BASE_URLS[1];

export const REGISTER = "Customer/Register";

export const GENERATE_OTP_LOGIN = "Customer/GenerateOTPLogin";

export const VERIFY_OTP_LOGIN = "Customer/VerifyOTPLogin";

export const LOGIN = "Customer/Login";

export const LOGIN_PHONE = "Customer/LoginPhone";

export const VERIFYOTP = "Customer/VerifyOtp";

export const REQUEST_PWD_RESET = "Customer/RequestPasswordReset";

export const CONFIRM_PWD_RESET = "Customer/ConfirmPasswordReset";

export const GET_NOTIFICATIONS = "CustomerSettings/GetNotifications";

export const TOGGLE_TRADES = "CustomerSettings/ToggleEmailALertsForTrades";

export const TOGGLE_DEPOSITS = "CustomerSettings/ToggleEmailALertsForDeposits";

export const TOGGLE_WITHDRAWALS = "CustomerSettings/ToggleEmailALertsForWithdrawals";

export const TOGGLE_NEWSLETTER = "CustomerSettings/ToggleEmailALertsForNewsLetter";

export const TOGGLE_GUPDATES = "CustomerSettings/ToggleEmailALertsForGeneralUpdates";

export const CHANGE_PWD = "CustomerSettings/ChangePassword";

export const CHANGE_PIN = "CustomerSettings/ChangePin";

export const VERIFY_PIN = "PIN/VerifyPIN";

export const GET_WALLET_AMOUNT = "Wallet/GetWalletAmount";

export const GET_LATEST_RATES = "Rates/GetLatestRates";

export const DEPOSIT = "Wallet/Deposit";

export const WITHDRAW = "Wallet/Withdraw";

export const BUY = "Wallet/Buy";

export const SELL = "Wallet/Sell";

export const SWAP = "Wallet/Swap";

export const LIMITCHECK = "Wallet/LimitCheck";

export const QUICK_TRANSACT = "Wallet/QuickTransact";

export const GET_TRANSACTION_LIST = "Transaction/GetTransactionList";

export const UPDATE_TRANSACTION = "Transaction/UpdateTransaction";

export const GET_LIMIT = "Transaction/ReachedLimit";

export const ADD_BANK_ACCOUNT = "BankAccount/AddBankAccount";

export const GET_BANK_ACCOUNTS = "BankAccount/GetBankAccounts";

export const DELETE_BANK_ACCOUNT = "BankAccount/DeleteBankAccount";

export const GET_BANKS = "BankAccount/GetBankList";

export const GET_BANK_ACCOUNT_DETAILS = "BankAccount/RetrieveBankAccountDetails";

export const GET_CUSTOMERS = "User/GetCustomers";

export const GET_USER = "User/GetUser";

export const GET_ADMINS = "User/GetAdmins";

export const GET_MANAGERS = "User/GetManagers";

export const GET_SUPPORTS = "User/GetSupports";

export const UPDATE_USER = "User/UpdateUser";

export const DELETE_USER = "User/DeleteUser";

export const CUSTOMER_COUNT = "User/GetCustomerCount";

export const USER_COUNT = "User/GetUserCount";

export const TRANS_COUNT = "User/GetTransactionCount";

export const UPLOAD_FILE = "Document/UploadFile";

export const UPLOAD_FILE_TO_TELEGRAM = "Document/UploadFileToTelegram";

export const CHANGE_RATE = "Rates/AddRate";

export const ADD_CRYPTO_WALLET = "CryptoWallet/AddCryptoWallet";

export const GET_CRYPTO_WALLET = "CryptoWallet/GetLatestCryptoWallets";

export const CLOSE_ACCOUNT = "Account/CloseAccountToggle";

export const DELETE_ACCOUNT = "Account/DeleteAccountToggle";

export const FREEZE_ACCOUNT = "Account/FreezeAccountToggle";

export const ADD_TIMER = "Timer/AddTimer";

export const GET_TIMERS = "Timer/GetLatestTimer";

export const CHANGE_FEE = "Fee/AddFee";

export const GET_LATEST_FEES = "Fee/GetLatestFees";
