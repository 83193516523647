import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Index";
import Input from "../../../Components/Input/Index";
import InputCtnr from "../../../Components/InputCtnr/Index";
import PhoneInput from "./PhoneInput";
import Title from "./Title";
import styles from "./../css/index.module.css";

const StepOne = ({stepOneInfo, setStepOneInfo, setLevel}) => {

    let setChange = (value) => {
        setStepOneInfo(prevState => {
            return{
                ...prevState,
                email : value
            }
        })
    } 

    let setAction = () => {
        if(stepOneInfo.phoneNumber.length > 0 && stepOneInfo.email.length > 0){
            console.log(stepOneInfo);
            setLevel(1);
        }
    }

    let setCode = (value) => {
        setStepOneInfo(prevState => {
            return{
                ...prevState,
                phoneCode : value
            }
        })
    }

    return(
        <div className={styles.StepOne}>
            <Title title={"Sign up"} currentValue={1}/>
            <InputCtnr label={"Phone Number"}>
                <PhoneInput value={stepOneInfo.phoneNumber} setPhone={setStepOneInfo} setCode={setCode}/>
            </InputCtnr>
            <InputCtnr label={"Email Address"}>
                <Input value={stepOneInfo.email} setChange={setChange} type={"email"} placeholder={"michaelreyes@gmail.com"}/>
            </InputCtnr>
            <p>
            By proceeding to create an account, you agree to our Terms of Use and Privacy Policy
            </p>
            <Button 
                text={"Get Started"} 
                isEnabled={stepOneInfo.phoneNumber.length > 0 && stepOneInfo.email.length > 0}
                action={setAction}

                />
            <p>Already have an account?<Link to={'/Login'}>Log in</Link></p>
        </div>
    );

}

export default StepOne;