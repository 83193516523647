import Title from "./Title";
import styles from "./../css/index.module.css";
import OtpInput from 'react-otp-input';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { BASE_URL, QUICK_TRANSACT } from "../../../Utils/Endpoint";
//import AuthContext from "../../../store/AuthContext";


const StepFour = ({userDetails}) => {
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    //const AuthCtx = useContext(AuthContext);

    useEffect(() => {

        let verifyOtp = async () => {
            //console.log(email)
            if(otp.length === 4){
                let obj = JSON.parse(localStorage.getItem("transaction"))
                var response = await fetch(BASE_URL + QUICK_TRANSACT,
                    {
                        method : "POST",
                        body : JSON.stringify({
                           // "email": email,
                           ...obj,
                            "oTP": otp,
                            userDetails: userDetails.overLimit ? userDetails : null
                        }),
                        headers : { 
                            'content-type': 'application/json',
                        }
                    })

                let json = await response.json();


                if(response.ok){
                    //localStorage.removeItem("transaction");
                    var transaction = JSON.parse(localStorage.getItem("transaction"));
                    localStorage.setItem("transaction", JSON.stringify({
                        ...transaction,
                        Id: json.data.transactionId
                    }))
                    setErrorMessage('');
                    navigate('/Seamless/Processing',{replace : true})
                    //AuthCtx.login(json.data.token,json.data.roleName[0]);
                    return null;
                }
                
                console.log(json);
                
                setErrorMessage(json.statusText);
                return null;
            }
        }

        verifyOtp();
            
    },[otp, navigate, userDetails])


    return(
    <div className={styles.StepFour}>
        <Title
            title={"Verification"}
            currentValue={5}
        />
        <p>
            To complete sign up, please enter the verification code we sent to you.
            <br/>It might take a few minutes to receive your code.
        </p>
        <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            containerStyle={styles.OtpInputCtnr}
            inputStyle={styles.OtpInput}
            inputType={"tel"}
        />
        <span className={styles.ErrorMessage}>{errorMessage}</span>
        <p>
        Didn’t get a code? <span>Send again</span>
        </p>
    </div>)

}

export default StepFour;