import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Index";
import Input from "../../Components/Input/Index";
import InputCtnr from "../../Components/InputCtnr/Index";
import OnBoardingCtnr from "../../Components/OnBoardingCtnr/Index";
import { Link /*, useNavigate */} from "react-router-dom";
import styles from "./css/index.module.css";
import AuthContext from "../../store/AuthContext";
import { BASE_URL, GENERATE_OTP_LOGIN, LOGIN, LOGIN_PHONE, VERIFY_OTP_LOGIN } from "../../Utils/Endpoint";
import { useNavigate } from "react-router-dom";
import useGaTracker from "./../../Utils/useGATracker";
import PhoneInput from "../OnBoarding/Components/PhoneInput";
import OtpInput from 'react-otp-input';




const Login = () => {

    useGaTracker();

    const [loginInfo, setLoginInfo] = useState({email : '', password : ''});
    const [errorMessage, setErrorMessage] = useState('');
    const AuthCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [isphoneNumberLogin, setPhoneNumberLogin] = useState(false);
    const [phoneInfo, setPhone] = useState({phoneNumber: ''});
    const [phoneCode, setCode] = useState('');
    const [isOTP, setIsOTP] = useState(false);
    const [pin, setPin] = useState('');



    let setEmailChange = (value) => {
        setLoginInfo(prevState => {
            return{
                ...prevState,
                email : value
            }
        });
    };

    let setPasswordChange = (value) => {
        setLoginInfo(prevState => {
            return{
                ...prevState,
                password : value
            }
        });
    };

    let submitAction = (e) => {
        e.preventDefault();

        LoginAction();
    }

    let PhoneLoginAction = async () => {
        if(phoneInfo.phoneNumber.length > 0){

        setErrorMessage('');
            var response = await fetch(BASE_URL + GENERATE_OTP_LOGIN,
                {
                    method : "POST",
                    body : JSON.stringify({
                        "phoneNumber": `${phoneCode}${phoneInfo.phoneNumber.charAt(0) === '0' ?
                            phoneInfo.phoneNumber.substring(1) : 
                            phoneInfo.phoneNumber}`
                      }),
                    headers : {
                        'content-type': 'application/json',
                    }
                })

                let json = await response.json();
                console.log(json);

                if(response.ok){
                    setErrorMessage('');
                    setIsOTP(true);
                    //AuthCtx.login(json.data.token,json.data.roleName.includes("Customer") ? "/Dashboard" : "/Admin");
                    //AuthCtx.login(json.data.token,json.data.roleName[0]);
                    return null;
                }

                setErrorMessage(json.statusText);
                return null;
            //console.log(loginInfo)
            //navigate('/Dashboard', {replace : true});
            
        }
    }

    let VerifyLoginAction = async () => {
        if(phoneInfo.phoneNumber.length > 0){

        setErrorMessage('');
            var response = await fetch(BASE_URL + VERIFY_OTP_LOGIN,
                {
                    method : "POST",
                    body : JSON.stringify({
                        "phoneNumber": `${phoneCode}${phoneInfo.phoneNumber.charAt(0) === '0' ?
                            phoneInfo.phoneNumber.substring(1) : 
                            phoneInfo.phoneNumber}`,
                            "OTP": pin
                      }),
                    headers : {
                        'content-type': 'application/json',
                    }
                })

                let json = await response.json();
                console.log(json);

                if(response.ok){
                    setErrorMessage('');
                    //AuthCtx.login(json.data.token,json.data.roleName.includes("Customer") ? "/Dashboard" : "/Admin");
                    AuthCtx.login(json.data.token,json.data.roleName[0]);
                    return null;
                }

                setErrorMessage(json.statusText);
                return null;
            //console.log(loginInfo)
            //navigate('/Dashboard', {replace : true});
            
        }
    }

    let LoginAction = async () => {
        if(loginInfo.email.length > 0 && loginInfo.password.length > 0){

            setErrorMessage('');
            var response = await fetch(BASE_URL + LOGIN,
                {
                    method : "POST",
                    body : JSON.stringify({
                        "email": loginInfo.email,
                        "password": loginInfo.password
                      }),
                    headers : {
                        'content-type': 'application/json',
                    }
                })

                let json = await response.json();
                console.log(json);

                if(response.ok){
                    setErrorMessage('');
                    //AuthCtx.login(json.data.token,json.data.roleName.includes("Customer") ? "/Dashboard" : "/Admin");
                    AuthCtx.login(json.data.token,json.data.roleName[0]);
                    return null;
                }

                setErrorMessage(json.statusText);
                return null;
            //console.log(loginInfo)
            //navigate('/Dashboard', {replace : true});
            
        }

    }

    useEffect(() => {
        
        if(AuthCtx.isLoggedIn)
            navigate(AuthCtx.role === "Customer" ? "/Dashboard" : "/Admin");

    },[AuthCtx,navigate])

    return(
        <OnBoardingCtnr>
            {isphoneNumberLogin ? 
                <div className={styles.Login}>
                <h3>Log in with Phone Nummber</h3>
                {!isOTP ?
                <PhoneInput
                value={phoneInfo.phoneNumber}
                            setPhone={setPhone}
                            setCode={setCode}
                 />
                 :
                 <OtpInput
                    value={pin}
                    onChange={setPin}
                    numInputs={4}
                    renderInput={(props) => <input {...props}/>}
                    containerStyle={styles.OtpInputCtnr}
                    inputStyle={styles.OtpInput}
                    inputType={"tel"}
                />
                }
                <p className={styles.marginBottom}>Login with email?<button onClick={() => setPhoneNumberLogin(false)}>Click here</button></p>
                 <Button 
                    text={isOTP ? "Login" : "Continue" }
                    isEnabled={phoneInfo.phoneNumber.length > 0}
                    action={isOTP ? VerifyLoginAction : PhoneLoginAction}
                />
                <span className={styles.ErrorMessage}>{errorMessage}</span>
                 </div>
            :
            <form className={styles.Login} onSubmit={(e) => {submitAction(e)}}>
                <h3>Log in</h3>
                <InputCtnr label={"Email Address"}>
                    <Input 
                        type={"email"}
                        setChange={setEmailChange}
                        value={loginInfo.email}
                    />
                </InputCtnr>
                <InputCtnr label={"Password"} smallMargin={true}>
                    <Input 
                        type={"password"}
                        isPassword={true}
                        setChange={setPasswordChange}
                        value={loginInfo.password}
                    />
                </InputCtnr>
                <Link to={'/ForgotPassword'}>Forgot Password?</Link>
                <Button 
                    text={"Log in"}
                    isEnabled={loginInfo.email.length > 0 && loginInfo.password.length > 0}
                    action={LoginAction}
                />
                <p>Login with phone number?<button onClick={() => setPhoneNumberLogin(true)}>Click here</button></p>
                <span className={styles.ErrorMessage}>{errorMessage}</span>

                <p>New to CoinNest?<Link to={'/OnBoarding'}>Get started</Link></p>
            </form>}
        </OnBoardingCtnr>
    );

}

export default Login;