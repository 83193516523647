import { useEffect, useState } from "react";
import styles from "./../css/index.module.css";
import ArrowDown from "./../../../img/ArrowDown.png";
import GHFlag from "./../../../img/GH.png";
import NGFlag from "./../../../img/NG.png";
import PhoneSearch from "./../../../img/PhoneSearch.png";

const PhoneInput = ({value, setPhone, setCode}) => {
    const [isOpen,setIsOpen] = useState(false);
    let initCountryState = [
        {
            "CountryName" : "Ghana",
            "CountryCode" : "GH",
            "PhoneCode" : "+233",
            "ImgUrl" : GHFlag
        },
        {
            "CountryName" : "Nigeria",
            "CountryCode" : "NG",
            "PhoneCode" : "+234",
            "ImgUrl" : NGFlag
        }
    ]
    const [countryCode, setCountryCode] = useState(initCountryState);
    const [activeCode, setActiveCode] = useState(countryCode[1]);

    

    useEffect(() => {
        let setCodeHandler = (value) => {
            setPhone(prevState => {
                return {
                    ...prevState,
                    phoneCode : value
                }
            });
        }

        setCodeHandler(activeCode.PhoneCode);
        setCode(activeCode.PhoneCode);
    },[setPhone, activeCode, setCode])

    let setPhoneHandler = (value) => {
        setPhone(prevState => {
            return {
                ...prevState,
                phoneNumber : value
            }
        });
    }

    


    return(
    <div className={styles.PhoneInput}>
        <div>
            <div onClick={() => {setIsOpen(prevState => !prevState);setCountryCode(initCountryState);}}>
                <img src={activeCode.ImgUrl} alt={activeCode.CountryCode + " flag"}/>
                <span>{activeCode.CountryCode}</span>
                <img src={ArrowDown} alt="arrow down" />
            </div>
            <span>{activeCode.PhoneCode}</span>
            <input value={value} onChange={(e) => setPhoneHandler(e.target.value)} type={'tel'}/>
        </div>
        {isOpen &&
        <div>
            <div>
                <img src={PhoneSearch} alt="Phone Search"/>
                <input 
                    type={"search"} 
                    placeholder={"Search country"} 
                    onChange={(e) => setCountryCode(prevState => initCountryState.filter(str => str.CountryName.toLowerCase().includes(e.target.value.toLowerCase())))} />
            </div>
            <div>
             {countryCode.map((country, index) => {
                return <div key={index} onClick={() => {setActiveCode(country);setIsOpen(false);setCountryCode(initCountryState);}}>
                    <div>
                        <img src={country.ImgUrl} alt={country.CountryName + " flag"} />
                        <span>{country.CountryName}</span>
                    </div>
                    <span>{country.PhoneCode}</span>
                </div>
             })}   
            </div>
        </div>}
    </div>
    );

}

export default PhoneInput;